<template>
  <b-overlay :show="loading">
    <!-- <template #modal-title>
        <span class="text-uclan">Subject: {{ form.subject }}</span>
    </template> -->

    <div class="form-row justify-content-center mb-1 mt-1">
        <div class="col-12">
            <div class="card">
                <div class="card-body p-0 wait_me_insert_form sameple_content">
                    <div class="form-row">
                        <div class="col-12">
                            <h3 class="text-center title text-white font-size-24" v-if="form.header" v-html="form.header"></h3>
                            <!-- <p class="mt-1 body_text" v-html="getEmailBody1stPart(row.body_text)"></p> -->
                            <p class="mt-4 p-3 body_text" v-if="form.email_body" v-html="form.email_body"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row mt-4">
                <div class="col-12 text-center">
                    <!-- <p>Kind Regards</p> -->
                    <!-- <p class="mb-0">Agent Commission Team</p> -->
                    <p>
                      COPYRIGHT ©2023 <span v-if="siteInfo.site_name" v-html="siteInfo.site_name"></span>, All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="form-row">
        <div class="col-12">
            <div class="text-center mt-1">
                <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px">You are receiving this email at  <a :href="'mailto:' + authUserData.email" v-html="authUserData.email"></a>  because it is officially registered  with the University Finance office</p>
                <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px; font-weight: bold" class="footer_text" v-if="authUserData.university" v-html="authUserData.university.name + ', ' + authUserData.university.address"></p>
                <div v-if="universityItem" class="text-center mt-2 mb-5">
                    <img :src="universityItem.logo_url" width="150px" alt="logo">
                </div>
            </div>
        </div>
    </div> -->
    <div class="row mt-3">
      <div class="col-sm-3"></div>
      <div class="col text-right">
          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-2')">Close</b-button>
      </div>
    </div>
  </b-overlay>
</template>
<script>
// import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  components: {
  },
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      siteInfo: '',
      form: {
        id: '',
        template_name: '',
        email_code: '',
        subject: '',
        header: '',
        email_body: ''
      },
      loading: false
    }
  },
  created () {
    if (this.editItem) {
      this.form = JSON.parse(this.editItem)
      console.log('this.form', this.form)
    }
  },
  methods: {
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }

.sameple_content {

}
.sameple_content .title {
    padding: 30px;
    background: #00CFDD;
    color: #000;
    margin: 0;
    font-size: 16px;
}
.sameple_content .body_text {
    margin-bottom: 60px;
}
.sameple_content .button {
    display:inline-block;
    background:#ffffff;
    color:#047eaf;
    font-family:Ubuntu, Helvetica, Arial, sans-serif;
    font-size:22px;
    font-weight:normal;
    line-height:120%;
    margin:0;
    text-decoration:none;
    text-transform:none;
    padding:10px 25px;
    border-radius:25px;
}
.sameple_content .footer_text {
    font-family:Ubuntu, Helvetica, Arial, sans-serif;
    line-height:20px;
    text-align:center;
    color:#999;
    font-family:'open sans',
    sans-serif;
    font-size:11px;
    font-weight:bold;
}
</style>
